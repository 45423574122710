import { Component } from '@angular/core';
import {CommonModule, NgOptimizedImage} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {RouterLink, RouterLinkActive} from "@angular/router";

@Component({
  selector: 'app-main-menu',
  standalone: true,
  imports: [CommonModule, TranslateModule, RouterLinkActive, RouterLink, NgOptimizedImage],
  templateUrl: './main-menu.component.html',
  styleUrl: './main-menu.component.scss'
})
export class MainMenuComponent {

  protected readonly TranslateModule = TranslateModule;
}
